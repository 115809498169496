import _ from 'lodash';
import { checkVersion } from './tool-check-resource';
import { Address, Restaurant } from '../models';
import { LOCAL_STORAGE_KEYS } from './local-storage.constant';
import { LocalStorageManager } from './tool-local-storage';
import { GOOGLE_MAP_KEY } from '../gconfig';
import { PolygonPoint } from '../models/abstract/polygon_point.entity';
import { LevelDeliveryFee } from '../models/abstract/level-delivery-fee.entity';
import apiManage from '../request';
import moment from 'moment';

/**
 * 检查多个地址地址是否在餐馆的配送范围内
 * @restaurant 餐馆
 * @address 多个地址
 */
export async function checkAddress(restaurant: GoogleMapRestaurant, address: Address[]) {
    // init
    const qualified: Address[] = [];   // 匹配上的
    const failed: Address[] = [];      // 没有匹配上的
    let result: Address[] = [];
    try {
        // 餐厅的信息地址
        const { google_map_lat, google_map_lng } = restaurant;
        const restaurantsLatLng = new google.maps.LatLng(Number(google_map_lat), Number(google_map_lng));
        for (const item of address) {
            // 地址的经纬度
            const addressGoogleMapLat = Number(item.google_map_lat);
            const addressGoogleMapLng = Number(item.google_map_lng);
            const customerLatLng = new google.maps.LatLng(addressGoogleMapLat, addressGoogleMapLng);
            // 餐厅与地址的距离转成公里
            const line = google.maps.geometry.spherical.computeDistanceBetween(restaurantsLatLng, customerLatLng) / 1000;
            // 匹配区域配送费
            const getDeliveryLevel = matchingAreaDeliveryFee(restaurant.setting_level_delivery || [], { lat: addressGoogleMapLat, lng: addressGoogleMapLng });
            if (line >= 0 && getDeliveryLevel && getDeliveryLevel.length > 0) {
                qualified.push({ ...item, line });
            } else {
                failed.push({ ...item, line });
            }
        }
        failed.map((v) => {
            return v.isWithin = false;
        });
        qualified.map((v) => {
            return v.isWithin = true;
        });
        result = _.concat(qualified, failed)
    } catch (error) {
        console.log(error, '----error');
        address.map((v) => {
            v.isWithin = false
            v.line = undefined
            return v
        });
        result = address;
    }
    // 计算调用GoogleApi次数
    const groupStringId = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_GROUP_STRING_ID);
    const resStringId = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
    const levelDeliveryNum = (restaurant.setting_level_delivery || []).map((n) => n.status).length;
    if (address.length * levelDeliveryNum !== 0) {
        await apiManage.post_record_google_api(groupStringId, {
            data: [{
                url: 'Maps JavaScript API',
                num: address.length * levelDeliveryNum,
                record_time: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                status: '0',
                res_string_id: resStringId,
                business: '1'
            }]
        })
    }
    return result
}

/**
 * 检查多个餐馆配送范围是否在地址
 * @address 一个地址
 * @restaurant 多个餐馆
 */
export async function checkRestaurants(address: Address, restaurants: GoogleMapRestaurant[]) {
    // init
    const qualified: GoogleMapRestaurant[] = [];   // 匹配上的
    let failed: GoogleMapRestaurant[] = [];      // 没有匹配上的
    // 地址为空时，返回原来的餐馆数组
    if (Object.keys(address).length === 0) {
        restaurants.map((v) => {
            return v.isWithin = true
        })
        return restaurants
    }
    try {
        // 顾客选择的地址
        const { google_map_lat, google_map_lng } = address;
        const customerLatLng = new google.maps.LatLng(Number(google_map_lat), Number(google_map_lng));
        if (google_map_lat && google_map_lat) {
            for (const item of restaurants) {
                // 餐厅的经纬度
                const restaurantGoogleMapLat = Number(item.google_map_lat);
                const restaurantGoogleMapLng = Number(item.google_map_lng);
                const restaurantsLatLng = new google.maps.LatLng(restaurantGoogleMapLat, restaurantGoogleMapLng);
                // 所选地址与餐厅的距离转成公里
                const line = google.maps.geometry.spherical.computeDistanceBetween(restaurantsLatLng, customerLatLng) / 1000;
                // $ 需要判断餐馆的版本号是否是1.2之后。1.2之后才有圆环
                const currentVersion = _.get(item, 'version') || '0'
                const isUpdate = checkVersion(currentVersion, '1.2.0')
                if (isUpdate) {
                    // 检查圆环配送费的圆环圈数【根据餐馆的版本（基础版、专业版、企业版），圆环分别有（1、10、20）个】
                    const setting_level_delivery = item.setting_level_delivery;
                    // 是否在餐厅的圆环内
                    const getDeliveryLevel = _.find(setting_level_delivery,
                        (n) => { return Number(n.start_radius) <= line && Number(n.end_radius) >= line; })
                    // console.log(line, getDeliveryLevel);
                    if (line >= 0 && getDeliveryLevel) {
                        qualified.push(item);
                    } else {
                        failed.push(item);
                    }
                } else {
                    if (line >= 0 && (line <= (item.setting_order_radius || 0))) {
                        qualified.push(item);
                    } else {
                        failed.push(item);
                    }
                }
            }
        } else {
            failed = restaurants;
        }
    } catch (error) {
        // console.log(error, '----error');
        restaurants.map((v) => {
            return v.isWithin = true
        });
        return restaurants;
    }
    failed.map((v) => {
        return v.isWithin = false
    });
    qualified.map((v) => {
        return v.isWithin = true
    });
    const result = _.concat(qualified, failed);
    return result;
}

/**
 * 获取当前位置的坐标信息
 * @param newlat 经度
 * @param newlng 纬度
 */
export async function currentPositioning(newlat: number, newlng: number) {
    const language = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE)?.toLowerCase();
    const result = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${newlat},${newlng}&key=${GOOGLE_MAP_KEY}&language=${language}`,
        {
            'method': 'GET'
        })
        .then(response => response.json())
        .then(response => {
            if (response.status === 'OK'
                && Array.isArray(response.results)
                && response.results.length > 0) {
                return {
                    google_map_lat: newlat,
                    google_map_lng: newlng,
                    street: response.results[0].formatted_address
                }
            }
        })
        .catch(err => console.log(err))
    return result
}


/**
 * 计算餐馆与所选地址的，路径距离
 * @param restaurant 餐馆
 * @param address 所选地址
 */
export async function checkDistance(restaurant: GoogleMapRestaurant, address: Address) {

    const newSaveAddress = address;
    try {
        // 用户地址经纬度
        const restaurantGoogleMapLat = Number(address.google_map_lat);
        const restaurantGoogleMapLng = Number(address.google_map_lng);
        const customerLatLng = new google.maps.LatLng(restaurantGoogleMapLat, restaurantGoogleMapLng);
        // 餐馆的经纬度
        const { google_map_lat, google_map_lng } = restaurant;
        const restaurantsLatLng = new google.maps.LatLng(Number(google_map_lat), Number(google_map_lng));

        const result = await distanceMatrixService(restaurantsLatLng, customerLatLng);
        if (result.line >= 0) {
            _.set(newSaveAddress, 'deliveryLine', result.line);
        }
        const groupStringId = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_GROUP_STRING_ID)
        const resStringId = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        let data = [{
            url: 'Distance Matrix API',
            num: result.getDistanceMatrixTime,
            record_time: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            status: '0',
            res_string_id: resStringId,
            business: '1'
        }, {
            url: 'Maps JavaScript API',
            num: result.computeDistanceBetweenTime,
            record_time: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            status: '0',
            res_string_id: resStringId,
            business: '1'
        }]
        data = data.filter((n) => { return n.num !== 0 });
        const api1 = apiManage.post_record_google_api(groupStringId, { data });
        const api2 = apiManage.post_record_google_api_info({
            data: [{
                url: 'Distance Matrix API',
                num: result.getDistanceMatrixTime,
                record_time: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                status: '0',
                res_string_id: resStringId,
                source: 'OD_WEB'
            }]
        });
        Promise.all([api1, api2]);
    } catch (error) {
        // console.log(error, 'checkDistance');
    }
    return newSaveAddress
}

/**
 * 获取餐馆与地址的路径距离(谷歌地图方法)
 * @param startLat 开始
 * @param endLng 目的地
 * @param travelMode 出行方式
 */
export async function distanceMatrixService(startLat: google.maps.LatLng, endLng: google.maps.LatLng,
    travelMode?: google.maps.TravelMode) {
    let line = -1;
    let getDistanceMatrixTime = 1;
    let computeDistanceBetweenTime = 0;

    await new Promise((resolve: any, reject: any) => {
        try {
            const server = new google.maps.DistanceMatrixService();
            server.getDistanceMatrix({
                origins: [startLat],                                 // 开始
                destinations: [endLng],                              // 目的地
                travelMode: travelMode ? travelMode : google.maps.TravelMode.DRIVING,   // 出行方式
                // transitOptions: TransitOptions,                   // 运输方式
                // drivingOptions: DrivingOptions,                   // 驾驶选项
                unitSystem: google.maps.UnitSystem.METRIC   // 显示距离单位制  google.maps.UnitSystem.IMPERIAL  / google.maps.UnitSystem.METRIC(默认)
                // avoidHighways: Boolean,                           // 尽可能避开高速路
                // avoidTolls: Boolean,                              // 尽可能避开收费
            }, (response: any, status: any) => {
                console.log('====responseresponseresponse=====', response);
                if (status === 'OK') {
                    if (response) {
                        // $ 距离使用路线距离（餐馆 => 顾客），首选是驾车方式，其次步行方式，最后是直线距离。
                        if (_.get(response, 'rows[0].elements[0].status') === 'OK') {
                            line = _.get(response, 'rows[0].elements[0].distance.value') * 0.001
                        } else if (travelMode === google.maps.TravelMode.DRIVING) {
                            getDistanceMatrixTime++;
                            distanceMatrixService(startLat, endLng, google.maps.TravelMode.WALKING)
                        } else {
                            computeDistanceBetweenTime++;
                            line = google.maps.geometry.spherical.computeDistanceBetween(startLat, endLng) / 1000;
                        }
                    }
                }
                resolve()
            });
        } catch (error) {
            reject(error);
        }
    })
    return { line, getDistanceMatrixTime, computeDistanceBetweenTime };
}

/**
 * 计算当前地址最近的餐馆
 * @param location 当前位置的经纬度
 * @param restaurantAddress 数组，多个餐馆地址经纬度
 */
export async function getNearestRestaurant(location: { lat: number, lng: number }, restaurantAddress: GoogleMapRestaurant[]) {
    // 最近的餐馆string_id
    let nearestRestaurantStringId = '';
    // 直线距离
    let nearestLine = 0;
    try {
        // 是否最近距离
        let hasNearestLine = false;
        if (restaurantAddress.length === 1) {
            nearestRestaurantStringId = restaurantAddress[0].string_id || ''
        } else if (restaurantAddress.length > 1) {
            // 当前位置
            const customerLatLng = new google.maps.LatLng(Number(location.lat), Number(location.lng));
            restaurantAddress.forEach((item) => {
                // 餐厅的经纬度
                const restaurantGoogleMapLat = Number(item.google_map_lat);
                const restaurantGoogleMapLng = Number(item.google_map_lng);
                const restaurantsLatLng = new google.maps.LatLng(restaurantGoogleMapLat, restaurantGoogleMapLng);
                // 所选地址与餐厅的距离转成公里
                const line = google.maps.geometry.spherical.computeDistanceBetween(restaurantsLatLng, customerLatLng) / 1000;
                console.log(item.string_id, ':', line)
                if (!hasNearestLine) {
                    nearestLine = line;
                    hasNearestLine = true;
                    nearestRestaurantStringId = item.string_id || '';
                } else {
                    if (line < nearestLine) {
                        nearestLine = line
                        nearestRestaurantStringId = item.string_id || '';
                    }
                }
            })
        }
    } catch (error) {
        console.log(error)
    }
    return { nearestRestaurantStringId }
}


/**
 * 点是否在区域内
 * @param Lat 用户地址经纬度
 * @param triangleCoords 多个点的数据（商家配送区域）
 */
export function containsLocation(LatLng: google.maps.LatLng, triangleCoords: PolygonPoint[]) {
    const paths = _.map(triangleCoords, (n) => { return { lat: Number(n.lat), lng: Number(n.lng) } });
    const bermudaTriangle = new google.maps.Polygon({ paths });
    const result = google.maps.geometry.poly.containsLocation(
        LatLng,
        bermudaTriangle
    )
    return result;
}

/**
 * 点是否在圆或圆环内
 * @param point
 * @param triangleCoords
 */
export function containsLocationByCircle(point: { lat: number, lng: number }, circleData: LevelDeliveryFee) {
    const circleLatLng = new google.maps.LatLng(Number(circleData.lat), Number(circleData.lng));
    const addressLatLng = new google.maps.LatLng(Number(point.lat), Number(point.lng));
    // 配送地址距离与圈原点距离
    const line = google.maps.geometry.spherical.computeDistanceBetween(circleLatLng, addressLatLng) / 1000;
    // 圆的开始半径
    const startRadius = Number(circleData.start_radius);
    // 圆的结束半径
    const endRadius = Number(circleData.end_radius);
    return startRadius <= line && line <= endRadius;
}

/**
 * 匹配区域配送费
 * @returns 符合的多个区域数组
 */
export function matchingAreaDeliveryFee(settingLevelDelivery: LevelDeliveryFee[], address: { lat: number, lng: number }) {
    const getDeliveryLevel: LevelDeliveryFee[] = [];
    const customerLatLng = new google.maps.LatLng(address.lat, address.lng);
    settingLevelDelivery.forEach((item) => {
        // item.status为true开启此区域
        if (item.status) {
            if ((item.type === 'CIRCLE' && containsLocationByCircle(address, item))
                || ((item.type === 'POLYGON' || item.type === 'RECTANGLE') && containsLocation(customerLatLng, item.polygon_points || []))
            ) {
                getDeliveryLevel.push(item);
            }
        }
    })
    return getDeliveryLevel;
}

// **********
// Type
// **********

export interface GoogleMapRestaurant extends Restaurant {
    google_map_lat?: number;
    google_map_lng?: number;
    setting_order_radius?: number;
    isWithin?: boolean;
}