import React from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import _ from 'lodash';
import { createHashHistory } from 'history';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { Address } from '../../models';
// import Notification from '../../component/snackbar/snackbar';
import i18n from '../../i18n';
import { GOOGLE_MAP_KEY } from '../../gconfig';
import './google-search.style.scss';
import apiManage from '../../request';
import moment from 'moment';

class GoogleMapComponent extends React.Component<Props> {
    // 地图上inputs
    searchBox: google.maps.places.SearchBox | undefined;
    // input框
    searchInput: HTMLInputElement | null = null;
    // 请求次数
    public requestTimes = 0;
    public state: State = {
        // 用户谷歌地图选中的详细地址
        formatted_address: '',
        componentError: false
    }

    // ************
    // 生命周期
    // ************

    UNSAFE_componentWillMount() {
        if (window.google === undefined) {
            this.setState({ componentError: true })
        }
    }
    public componentWillUnmount(): void {
        this.record();
    }
    async record() {
        if (this.requestTimes) {
            await apiManage.post_record_google_api_info({
                data: [{
                    url: 'Places API',
                    num: this.requestTimes,
                    record_time: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                    status: '0',
                    source: 'OD_WEB'
                }]
            });
        }
        this.requestTimes = 0;
    }

    // 获取搜索出来的地址
    addressInquiry(lat: any, lng: any) {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_KEY}&language=${LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE)?.toLowerCase() || 'en'}`,
            {
                'method': 'GET'
            })
            .then(response => response.json())
            .then(response => {
                if (response.status === google.maps.places.PlacesServiceStatus.OK
                    && Array.isArray(response.results)
                    && response.results.length > 0) {
                    const address = this.getAddress(response.results);
                    if (typeof this.props.onSave === 'function') this.props.onSave(address);
                }
            })
            .catch(err => console.log(err));

        apiManage.post_record_google_api_info({
            data: [{
                url: 'Geocoding API',
                num: 1,
                record_time: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                status: '0',
                source: 'OD_WEB'
            }]
        });
    }

    /**
     * 地址解析
     */
    getAddress = (result: any) => {
        // 输入框的值
        const value = this.searchInput?.value;
        const componentForm: any = {
            // street_number: '',
            route: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            //  UK and in Sweden, city
            postal_town: '',
            // Brooklyn and other parts of New York City
            sublocality_level_1: ''
        };
        const address = {
            // 门牌号
            street_number: '',
            // 街道
            route: '',
            // 城市
            locality: '',
            // state
            administrative_area_level_1: '',
            // 国家
            country: '',
            // 邮编
            postal_code: '',
            // 谷歌地图place.js中的id
            google_map_place_id: _.get(result, '[0].place_id'),
            // 可读地址
            google_map_formatted_address: value || _.get(result, '[0].formatted_address'),
            // 经度
            google_map_lat: _.get(result, '[0].geometry.location.lat', ''),
            // 纬度
            google_map_lng: _.get(result, '[0].geometry.location.lng', '')
        };
        let address_components: any[] = [];

        if (_.isArray(result)) {
            result.forEach((item) => {
                address_components = _.concat(address_components, item.address_components)
            })
        }
        let isSpecialCountry = false;
        for (const item of address_components) {
            const addressType = item.types[0];
            if (_.has(componentForm, `${addressType}`)) {
                // $ 处理中国特别行政区
                const longName = item.long_name
                const shortName = item.short_name
                const specialCountry = ['HK', 'MO', 'TW'];
                if (_.includes(specialCountry, shortName)) {
                    if (addressType === 'country') {
                        _.set(address, 'country', '中国')
                        _.set(address, 'administrative_area_level_1', longName)
                    }
                    isSpecialCountry = true
                }
                if (isSpecialCountry) {
                    if (addressType === 'administrative_area_level_1') {
                        _.set(address, 'locality', longName)
                    } else {
                        if (!_.get(address, `${addressType}`)) {
                            _.set(address, `${addressType}`, longName)
                        }
                    }
                } else {
                    if (!_.get(address, `${addressType}`)) {
                        _.set(address, `${addressType}`, longName)
                    }
                }

                // 处理特殊城市字段
                if (!_.get(address, 'locality')) {
                    if (addressType === 'postal_town') {
                        _.set(address, 'locality', longName)
                    }
                    else if (addressType === 'sublocality_level_1') {
                        _.set(address, 'locality', longName)
                    }

                }
            }
        }
        // 选中地址关闭提示
        const input = document.getElementById(`${i18n.t('text_client_zip_code')}`);
        const searchBox = document.getElementById('google-map-search');
        if (this.props.componentType === 'address') {
            if (searchBox) {
                searchBox.style.border = '1px solid #989eb6';
                searchBox.style.boxSizing = 'border-box'
            }
            if (input) input.style.border = '1px solid #989eb6'
        } else {
            if (searchBox) searchBox.style.border = '1px solid transparent';
        }

        this.props.setIsGoogleAdress?.(true);
        this.setState({ formatted_address: _.get(address, 'google_map_formatted_address', '') });
        this.props.setInputVal?.(_.get(address, 'google_map_formatted_address', ''))
        return address;

    }

    // $ 搜索
    standaloneSearchOnPlacesChanged = () => {
        try {
            if (this.searchBox) {
                const lat = this.searchBox.getPlaces()[0]?.geometry?.location?.lat();
                const lng = this.searchBox.getPlaces()[0]?.geometry?.location?.lng();
                this.setState({
                    center: {
                        lat,
                        lng
                    }
                })
                this.addressInquiry(lat, lng)
            }
        } catch (error) {
            console.log(error)
        }
    };

    /**
     * 判断是否在配送范围
     */
    // isDelivery = () => {
    //     try {
    //         // 顾客地址
    //         const customerAddress = this.state.center;
    //         if (typeof customerAddress === 'undefined') {
    //             return;
    //         }
    //         const { lat, lng } = customerAddress;
    //         // 商家信息
    //         let stringId: string | null = this.getQueryString('string_id');
    //         const restaurantsMag = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
    //         const restaurantInfo = _.get(restaurantsMag, `${stringId}.restaurant`);
    //         const { google_map_lat, google_map_lng, setting_level_delivery } = restaurantInfo;
    //         const customerLatLng = new google.maps.LatLng(lat, lng);
    //         const restaurantsLatLng = new google.maps.LatLng(google_map_lat, google_map_lng);
    //         // 餐厅与客户的距离转成公里
    //         // tslint:disable-next-line: max-line-length
    //         const line = google.maps.geometry.spherical.computeDistanceBetween(restaurantsLatLng, customerLatLng) / 1000;
    //         // 是否在餐厅的圆环内
    //         let getDeliveryLevel = _.find(setting_level_delivery,
    //             (n) => { return Number(n.start_radius) <= line && Number(n.end_radius) >= line; })
    //         if (getDeliveryLevel) {
    //             // 原始配送费
    //             let delivery_fee = getDeliveryLevel.delivery_fee;
    //             // 最少达到配送金额
    //             let delivery_minimum_fee = getDeliveryLevel.delivery_minimum_fee;
    //             let delivery_amount_discount = getDeliveryLevel.delivery_amount_discount;
    //             LocalStorageManager.setLocalStorage(
    //                 {
    //                     path: LOCAL_STORAGE_KEYS.DELIVERY_FEE,
    //                     value: {
    //                         delivery_fee,
    //                         delivery_minimum_fee,
    //                         delivery_amount_discount
    //                     }
    //                 }
    //             )
    //             return true;
    //         } else {
    //             Notification.notification(i18n.t('scan_reslist_page_the_current_address_is_not_within_the_scope_of_shipping'));
    //             return false;
    //         }
    //     } catch (error) {
    //         console.log(error, 'error');
    //     }
    // }

    /**
     * 获取URL问号后的参数值
     * @param name
     */
    public getQueryString(name) {
        const { search = '' } = createHashHistory().location;
        const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        const r = search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return null;
    }

    /**
     * input框失焦事件
     * @param name
     */
    public inputOnblurEvent(inputValue: string) {
        try {
            const { formatted_address } = this.state
            const { defaultValue } = this.props
            const searchBox = document.getElementById('google-map-search');
            const completeInfoBox = document.getElementById('completeInfoBox');

            // 判断输入框的值是否和谷歌搜索选中的值相同
            if (inputValue === formatted_address || defaultValue === inputValue || inputValue === '') {
                if (this.props.componentType === 'address') {
                    if (searchBox) searchBox.style.border = '1px solid #989eb6';
                } else if (this.props.componentType === 'completeInfo') {
                    if (searchBox) searchBox.style.border = 'unset';
                }
                this.props.setIsGoogleAdress?.(true);
            } else {
                if (this.props.componentType === 'address') {
                    if (searchBox) searchBox.style.border = '1px solid #fd7b88';
                } else if (this.props.componentType === 'completeInfo') {
                    if (searchBox) {
                        searchBox.style.boxSizing = 'border-box';
                        searchBox.style.border = '1px solid #fd7b88';
                        searchBox.style.height = 'unset';
                    }
                    if (completeInfoBox) completeInfoBox.style.margin = 'unset';
                }
                this.props.setIsGoogleAdress?.(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { defaultValue, setInputVal } = this.props;
        if (this.state.componentError) {
            return <div className='pleaseReloadText'>{i18n.t('general_text_Please_reload')}</div>
        } else {
            return (
                <div className={this.props.className ?? 'google-map-search'} id='google-map-search'>
                    <div>

                        <StandaloneSearchBox
                            onLoad={(searchBox: google.maps.places.SearchBox) => this.searchBox = searchBox}
                            onPlacesChanged={
                                this.standaloneSearchOnPlacesChanged
                            }
                        >
                            <div className='SearchBox' id='completeInfoBox'>
                                <input
                                    ref={(ref) => this.searchInput = ref}
                                    onClick={() => this.setState({ center: undefined })}
                                    onChange={(e) => { setInputVal?.(e.target.value); this.requestTimes = this.requestTimes + 1 }}
                                    type='text'
                                    placeholder={this.props.placeholder || ''}
                                    className={'SearchBoxinput'}
                                    defaultValue={defaultValue}
                                    onBlur={(e) => { this.inputOnblurEvent(e.target.value) }}
                                />
                            </div>
                        </StandaloneSearchBox>
                    </div>

                </div >
            )
        }
    }
}
export default GoogleMapComponent
// *********************
// Types
// *********************
type Props = {
    className?: string;
    placeholder?: string;
    onSave?: (val: Address) => void;
    setIsGoogleAdress?: (val: boolean) => void;
    // 初始值
    defaultValue: string;
    componentType: 'address' | 'completeInfo';
    setInputVal?: (val: string) => void;
}
type State = {
    formatted_address: string | undefined;
    componentError: boolean;
    // 当前选中的经纬度
    center?: {
        lat: number;
        lng: number;
    }
};
